import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Login from "../components/Login";
import { useState, useEffect } from "react";
import { supabase } from "../config/supabase";

interface Application {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  identity?: string;
  dob?: string;
  location?: string;
  job_title?: string;
  interests?: string[];
  other_clubs?: string;
  club_names?: string;
  instagram_handle?: string;
  contribution?: string;
  subscribed_to_updates?: boolean;
  status: string | null;
}

const AdminDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<{
    username: string;
    role: "admin" | "user";
  } | null>(null);
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from("membership_applications")
        .select("*");

      if (error) throw error;
      setApplications(data || []);
    } catch (err) {
      console.error("Error fetching applications:", err);
      setError("Failed to fetch applications.");
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (id: string, newStatus: string) => {
    try {
      const { error } = await supabase
        .from("membership_applications")
        .update({ status: newStatus })
        .eq("id", id);

      if (error) throw error;

      // Refresh applications after update
      fetchApplications();

      // If status is "accepted", send invite
      if (newStatus === "accepted") {
        const application = applications.find((app) => app.id === id);
        if (application) {
          sendInvite(
            application.email,
            `${application.first_name} ${application.last_name}`
          );
        }
      }
    } catch (err) {
      console.error("Error updating status:", err);
      setError("Failed to update status.");
    }
  };

  const sendInvite = async (email: string, fullName: string) => {
    try {
      // Fetch the user's session
      const { data, error } = await supabase.auth.getSession();
  
      if (error || !data.session) {
        throw new Error("User session not found. Please log in.");
      }
  
      const accessToken = data.session.access_token; 
      const inviteLink = `${
        window.location.origin
      }/signup?email=${encodeURIComponent(email)}&fullName=${encodeURIComponent(
        fullName
      )}`;
  
      const response = await fetch(
        `${import.meta.env.VITE_SUPABASE_FUNCTION_URL}/send_invite`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Include token in header
          },
          body: JSON.stringify({ email, fullName, inviteLink }),
        }
      );
  
      if (!response.ok) {
        const result = await response.json();
        throw new Error(result.error || "Failed to send invite");
      }
  
      console.log("Invite sent successfully to:", email);
    } catch (err) {
      console.error("Error sending invite:", err);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const handleLogout = () => {
    setUser(null);
    navigate("/admin");
  };

  if (!user) {
    return <Login onLogin={(user) => setUser(user)} />;
  }

  return (
    <div className="min-h-screen bg-[#faf9f7]">
      <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex justify-between items-center mb-12">
          <span className="text-red-800 font-serif text-2xl fixed left-8 top-8">
            Avenida
          </span>
          <button
            onClick={() => handleLogout()}
            className="fixed right-8 top-8 p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Logout"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="mt-24 space-y-8">
          <h2 className="text-4xl font-serif text-center mb-12">Admin Dashboard</h2>
          
          {error && (
            <p className="text-red-600 text-center mb-8">{error}</p>
          )}
          
          {loading ? (
            <p className="text-center text-gray-600">Loading applications...</p>
          ) : (
            <div className="overflow-x-auto bg-white rounded-lg shadow-sm border border-gray-100">
              <div className="min-w-[1400px]">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50 text-gray-600 text-sm">
                      <th className="p-4 text-left font-medium w-36">Name</th>
                      <th className="p-4 text-left font-medium w-48">Email</th>
                      <th className="p-4 text-left font-medium w-24">Identity</th>
                      <th className="p-4 text-left font-medium w-32">DOB</th>
                      <th className="p-4 text-left font-medium w-36">Location</th>
                      <th className="p-4 text-left font-medium w-36">Instagram</th>
                      <th className="p-4 text-left font-medium w-40">Job Title</th>
                      <th className="p-4 text-left font-medium w-28">Other Clubs</th>
                      <th className="p-4 text-left font-medium w-36">Club Names</th>
                      <th className="p-4 text-left font-medium w-64">Contribution</th>
                      <th className="p-4 text-left font-medium w-28">Subscribed</th>
                      <th className="p-4 text-left font-medium w-28">Status</th>
                      <th className="p-4 text-left font-medium w-40">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                    {applications.map((app) => (
                      <tr key={app.id} className="hover:bg-gray-50 transition-colors">
                        <td className="p-4 text-gray-600">
                          {app.first_name} {app.last_name}
                        </td>
                        <td className="p-4 text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap">
                          {app.email}
                        </td>
                        <td className="p-4 text-gray-600">{app.identity}</td>
                        <td className="p-4 text-gray-600">{app.dob}</td>
                        <td className="p-4 text-gray-600">{app.location}</td>
                        <td className="p-4 text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap">
                          {app.instagram_handle}
                        </td>
                        <td className="p-4 text-gray-600">{app.job_title}</td>
                        <td className="p-4 text-gray-600">{app.other_clubs}</td>
                        <td className="p-4 text-gray-600 overflow-hidden text-ellipsis whitespace-nowrap">
                          {app.club_names}
                        </td>
                        <td className="p-4 text-gray-600">
                          <div className="relative group">
                            <div className="max-h-24 overflow-y-auto whitespace-normal text-sm">
                              {app.contribution}
                            </div>
                            {app.contribution && app.contribution.length > 150 && (
                              <div className="hidden group-hover:block absolute z-10 bg-gray-900 text-white p-4 rounded-md shadow-lg max-w-md -left-1/4 mt-2">
                                {app.contribution}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="p-4 text-gray-600">
                          {app.subscribed_to_updates ? "Yes" : "No"}
                        </td>
                        <td className="p-4">
                          <span className={`inline-flex px-2 py-1 rounded-full text-xs font-medium ${
                            app.status === "accepted"
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}>
                            {app.status || "Pending"}
                          </span>
                        </td>
                        <td className="p-4 whitespace-nowrap">
                          <div className="flex gap-2">
                            <button
                              onClick={() => updateStatus(app.id, "accepted")}
                              className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                                app.status === "accepted"
                                  ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                                  : "bg-black text-white hover:bg-gray-800"
                              }`}
                              disabled={app.status === "accepted"}
                            >
                              Accept
                            </button>
                            {app.status === "accepted" && (
                              <button
                                onClick={() =>
                                  sendInvite(
                                    app.email,
                                    `${app.first_name} ${app.last_name}`
                                  )
                                }
                                className="px-3 py-1.5 rounded-full text-sm font-medium bg-blue-50 text-blue-600 hover:bg-blue-100 transition-colors"
                              >
                                Resend
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;